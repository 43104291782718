
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { getEmptyCustomFormMode, getEmptyDetailedAddress, getEmptyProfileMode } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { handleImages } from '@/util-functions/image-utils';
import SitchAddress from '@/components/custom-ui-components/SitchAddress.vue';
import SitchTextEditor from '@/components/custom-ui-components/SitchTextEditor.vue';
import SitchImageUpload from '@/components/custom-ui-components/SitchImageUpload.vue';
import {
  faLinkedin,
  faFacebook,
  faYoutube,
  faSnapchat,
  faXTwitter,
  faBluesky,
  faInstagram,
  faMedium,
  faTwitch,
  faSpotify,
  faPatreon,
  faReddit,
  faSoundcloud,
  faGithub,
  faDiscord,
  faGitlab,
  faStripeS,
  faTiktok,
  faDeviantart,
  faAppStoreIos,
  faGooglePlay,
  faKickstarterK,
  faAmazon,
  faPinterest,
  faVimeo,
  faWordpress,
  faShopify,
  faEtsy,
  fa500px,
  faEbay,
  faSteam,
  faPlaystation,
  faXbox,
  faItchIo,
  faSlack,
  faWhatsapp,
  faNpm,
  faMicrosoft,
  faMeetup,
  faAirbnb,
  faBitbucket,
  faFacebookMessenger,
  faMixcloud,
  faQuora,
  faStackOverflow,
  faWeixin,
  faBandcamp,
  faBitcoin,
  faPaypal,
  faProductHunt,
  faApplePay,
  faGooglePay,
  faTelegram,
  faYelp,
  faTumblr,
  faBattleNet,
  faThreads,  
} from '@fortawesome/free-brands-svg-icons';
import { faFolderOpen, faFileUser } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { sitchClientUrlNotLocalhost } from '@/util-functions/initialization-utils';
import { getLocationAddress, isAtMaxModes } from '@/util-functions/misc-utils';
import { eCustomFieldTypes, eModeType } from '@/enums';
import { t } from '@/util-functions/language-utils';
import { generateId } from '@/util-functions/misc-firestore-utils';
import { showPrompt } from '@/util-functions/notice-utils';

interface SocialObject {
  isUrl: boolean;
  displayName: string;
  urlVariableName: string;
  isVisibleVariableName: string;
  faIcon: string[];
  domain?: string;
}

const contactDetailsFormUrlOptionConstant = 'usingContactDetailsFormUrlOption';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchTextEditor,
    SitchAddress,
    SitchImageUpload,
  },
  data(): {
    modeForm: Omit<ProfileMode, keyof Mode>;
    imageSizes: ImageSizes;
    socialMediaOptions: SocialObject[];
    chatAppOptions: SocialObject[];
    videoOptions: SocialObject[];
    audioOptions: SocialObject[];
    gamingOptions: SocialObject[];
    commerceOptions: SocialObject[];
    paymentOptions: SocialObject[];
    developerOptions: SocialObject[];
    miscOptions: SocialObject[];
    allOptions: SocialObject[];
    genericOptions: SocialObject[];
    showCtaButtonOptions: boolean;
    showAdvancedOptions: boolean;
  } {
    // Links
    const socialMediaLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'LinkedIn',
        urlVariableName: 'linkedInUrl',
        isVisibleVariableName: 'isLinkedInUrlVisible',
        faIcon: ['fab', 'linkedin'],
      },
      {
        isUrl: true,
        displayName: 'Facebook',
        urlVariableName: 'facebookUrl',
        isVisibleVariableName: 'isFacebookUrlVisible',
        faIcon: ['fab', 'facebook'],
      },
      {
        isUrl: true,
        displayName: 'Reddit',
        urlVariableName: 'redditUrl',
        isVisibleVariableName: 'isRedditUrlVisible',
        faIcon: ['fab', 'reddit'],
      },
      {
        isUrl: true,
        displayName: 'DeviantArt',
        urlVariableName: 'deviantArtUrl',
        isVisibleVariableName: 'isDeviantArtUrlVisible',
        faIcon: ['fab', 'deviantart'],
      },
      {
        isUrl: true,
        displayName: 'Pinterest',
        urlVariableName: 'pinterestUrl',
        isVisibleVariableName: 'isPinterestUrlVisible',
        faIcon: ['fab', 'pinterest'],
      },
      {
        isUrl: true,
        displayName: '500px',
        urlVariableName: 'fiveHundredPxUrl',
        isVisibleVariableName: 'isFiveHundredPxUrlVisible',
        faIcon: ['fab', '500px'],
      },
      {
        isUrl: true,
        displayName: 'Meetup',
        urlVariableName: 'meetupUrl',
        isVisibleVariableName: 'isMeetupUrlVisible',
        faIcon: ['fab', 'meetup'],
      },
      {
        isUrl: true,
        displayName: 'Tumblr',
        urlVariableName: 'tumblrUrl',
        isVisibleVariableName: 'isTumblrUrlVisible',
        faIcon: ['fab', 'tumblr'],
      },
    ];
    const videoLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'Youtube',
        urlVariableName: 'youtubeUrl',
        isVisibleVariableName: 'isYoutubeUrlVisible',
        faIcon: ['fab', 'youtube'],
      },
      {
        isUrl: true,
        displayName: 'TikTok',
        urlVariableName: 'tikTokUrl',
        isVisibleVariableName: 'isTikTokUrlVisible',
        faIcon: ['fab', 'tiktok'],
      },
      {
        isUrl: true,
        displayName: 'Vimeo',
        urlVariableName: 'vimeoUrl',
        isVisibleVariableName: 'isVimeoUrlVisible',
        faIcon: ['fab', 'vimeo'],
      },
    ];
    const audioLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'SoundCloud',
        urlVariableName: 'soundcloudUrl',
        isVisibleVariableName: 'isSoundCloudUrlVisible',
        faIcon: ['fab', 'soundcloud'],
      },
      {
        isUrl: true,
        displayName: 'Mixcloud',
        urlVariableName: 'mixcloudUrl',
        isVisibleVariableName: 'isMixcloudUrlVisible',
        faIcon: ['fab', 'mixcloud'],
      },
      {
        isUrl: true,
        displayName: 'Bandcamp',
        urlVariableName: 'bandcampUrl',
        isVisibleVariableName: 'isBandcampUrlVisible',
        faIcon: ['fab', 'bandcamp'],
      },
      {
        isUrl: true,
        displayName: 'Spotify',
        urlVariableName: 'spotifyUrl',
        isVisibleVariableName: 'isSpotifyUrlVisible',
        faIcon: ['fab', 'spotify'],
      },
    ];
    const chatAppLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'Discord',
        urlVariableName: 'discordUrl',
        isVisibleVariableName: 'isDiscordUrlVisible',
        faIcon: ['fab', 'discord'],
      },
      {
        isUrl: true,
        displayName: 'Slack',
        urlVariableName: 'slackUrl',
        isVisibleVariableName: 'isSlackUrlVisible',
        faIcon: ['fab', 'slack'],
      },
      {
        isUrl: true,
        displayName: 'WhatsApp',
        urlVariableName: 'whatsappUrl',
        isVisibleVariableName: 'isWhatsAppUrlVisible',
        faIcon: ['fab', 'whatsapp'],
      },
      {
        isUrl: true,
        displayName: 'Messenger',
        urlVariableName: 'facebookMessengerUrl',
        isVisibleVariableName: 'isFacebookMessengerUrlVisible',
        faIcon: ['fab', 'facebook-messenger'],
      },
      {
        isUrl: true,
        displayName: 'We Chat',
        urlVariableName: 'weChatUrl',
        isVisibleVariableName: 'isWeChatUrlVisible',
        faIcon: ['fab', 'weixin'],
      },
      {
        isUrl: true,
        displayName: 'Clubhouse',
        urlVariableName: 'clubhouseUrl',
        isVisibleVariableName: 'isClubhouseUrlVisible',
        faIcon: ['far', 'hand-paper'],
      },
      {
        isUrl: true,
        displayName: 'Telegram',
        urlVariableName: 'telegramUrl',
        isVisibleVariableName: 'isTelegramUrlVisible',
        faIcon: ['fab', 'telegram'],
      },
    ];

    const gamingLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'Steam',
        urlVariableName: 'steamUrl',
        isVisibleVariableName: 'isSteamUrlVisible',
        faIcon: ['fab', 'steam'],
      },
      {
        isUrl: true,
        displayName: 'Playstation',
        urlVariableName: 'playstationUrl',
        isVisibleVariableName: 'isPlaystationUrlVisible',
        faIcon: ['fab', 'playstation'],
      },
      {
        isUrl: true,
        displayName: 'Xbox',
        urlVariableName: 'xboxUrl',
        isVisibleVariableName: 'isXboxUrlVisible',
        faIcon: ['fab', 'xbox'],
      },
      {
        isUrl: true,
        displayName: 'itch.io',
        urlVariableName: 'itchIoUrl',
        isVisibleVariableName: 'isItchIoUrlVisible',
        faIcon: ['fab', 'itch-io'],
      },
      {
        isUrl: true,
        displayName: 'Battle.net',
        urlVariableName: 'battleNetUrl',
        isVisibleVariableName: 'isBattleNetUrlVisible',
        faIcon: ['fab', 'battle-net'],
      },
    ];
    const commerceLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'Google',
        urlVariableName: 'googleUrl',
        isVisibleVariableName: 'isGoogleVisible',
        faIcon: ['fab', 'google'],
      },
      {
        isUrl: true,
        displayName: 'Patreon',
        urlVariableName: 'patreonUrl',
        isVisibleVariableName: 'isPatreonUrlVisible',
        faIcon: ['fab', 'patreon'],
      },
      {
        isUrl: true,
        displayName: 'Kickstarter',
        urlVariableName: 'kickstarterUrl',
        isVisibleVariableName: 'isKickstarterUrlVisible',
        faIcon: ['fab', 'kickstarter-k'],
      },
      {
        isUrl: true,
        displayName: 'Play Store',
        urlVariableName: 'playStoreUrl',
        isVisibleVariableName: 'isPlayStoreUrlVisible',
        faIcon: ['fab', 'google-play'],
      },
      {
        isUrl: true,
        displayName: 'App Store',
        urlVariableName: 'appStoreUrl',
        isVisibleVariableName: 'isAppStoreUrlVisible',
        faIcon: ['fab', 'app-store-ios'],
      },
      {
        isUrl: true,
        displayName: 'Microsoft',
        urlVariableName: 'microsoftUrl',
        isVisibleVariableName: 'isMicrosoftUrlVisible',
        faIcon: ['fab', 'microsoft'],
      },
      {
        isUrl: true,
        displayName: 'Amazon',
        urlVariableName: 'amazonUrl',
        isVisibleVariableName: 'isAmazonUrlVisible',
        faIcon: ['fab', 'amazon'],
      },
      {
        isUrl: true,
        displayName: 'Ebay',
        urlVariableName: 'ebayUrl',
        isVisibleVariableName: 'isEbayUrlVisible',
        faIcon: ['fab', 'ebay'],
      },
      {
        isUrl: true,
        displayName: 'Shopify',
        urlVariableName: 'shopifyUrl',
        isVisibleVariableName: 'isShopifyUrlVisible',
        faIcon: ['fab', 'shopify'],
      },
      {
        isUrl: true,
        displayName: 'Etsy',
        urlVariableName: 'etsyUrl',
        isVisibleVariableName: 'isEtsyUrlVisible',
        faIcon: ['fab', 'etsy'],
      },
      {
        isUrl: true,
        displayName: 'Airbnb',
        urlVariableName: 'airbnbUrl',
        isVisibleVariableName: 'isAirbnbUrlVisible',
        faIcon: ['fab', 'airbnb'],
      },
    ];
    const paymentLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'Bitcoin',
        urlVariableName: 'bitcoinUrl',
        isVisibleVariableName: 'isBitcoinUrlVisible',
        faIcon: ['fab', 'bitcoin'],
      },
      {
        isUrl: true,
        displayName: 'Paypal',
        urlVariableName: 'paypalUrl',
        isVisibleVariableName: 'isPaypalUrlVisible',
        faIcon: ['fab', 'paypal'],
      },
      {
        isUrl: true,
        displayName: 'Cash App',
        urlVariableName: 'cashAppUrl',
        isVisibleVariableName: 'isCashAppUrlVisible',
        faIcon: ['fas', 'usd-square'],
      },
      {
        isUrl: true,
        displayName: 'Google Pay',
        urlVariableName: 'googlePayUrl',
        isVisibleVariableName: 'isGooglePayUrlVisible',
        faIcon: ['fab', 'google-pay'],
      },
      {
        isUrl: true,
        displayName: 'Apple Pay',
        urlVariableName: 'applePayUrl',
        isVisibleVariableName: 'isApplePayUrlVisible',
        faIcon: ['fab', 'apple-pay'],
      },
    ];
    const developerLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'Github',
        urlVariableName: 'githubUrl',
        isVisibleVariableName: 'isGithubUrlVisible',
        faIcon: ['fab', 'github'],
      },
      {
        isUrl: true,
        displayName: 'Gitlab',
        urlVariableName: 'gitlabUrl',
        isVisibleVariableName: 'isGitlabUrlVisible',
        faIcon: ['fab', 'gitlab'],
      },
      {
        isUrl: true,
        displayName: 'Npm',
        urlVariableName: 'npmUrl',
        isVisibleVariableName: 'isNpmUrlVisible',
        faIcon: ['fab', 'npm'],
      },

      {
        isUrl: true,
        displayName: 'Bitbucket',
        urlVariableName: 'bitbucketUrl',
        isVisibleVariableName: 'isBitbucketUrlVisible',
        faIcon: ['fab', 'bitbucket'],
      },
      {
        isUrl: true,
        displayName: 'Stack Overflow',
        urlVariableName: 'stackOverflowUrl',
        isVisibleVariableName: 'isStackOverflowUrlVisible',
        faIcon: ['fab', 'stack-overflow'],
      },
    ];
    const miscLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: 'WordPress',
        urlVariableName: 'wordpressUrl',
        isVisibleVariableName: 'isWordpressUrlVisible',
        faIcon: ['fab', 'wordpress'],
      },
      {
        isUrl: true,
        displayName: 'Product Hunt',
        urlVariableName: 'productHuntUrl',
        isVisibleVariableName: 'isProductHuntUrlVisible',
        faIcon: ['fab', 'product-hunt'],
      },
      {
        isUrl: true,
        displayName: 'Quora',
        urlVariableName: 'quoraUrl',
        isVisibleVariableName: 'isQuoraUrlVisible',
        faIcon: ['fab', 'quora'],
      },
      {
        isUrl: true,
        displayName: 'Yelp',
        urlVariableName: 'yelpUrl',
        isVisibleVariableName: 'isYelpUrlVisible',
        faIcon: ['fab', 'yelp'],
      },
    ];
    const genericLinks: SocialObject[] = [
      {
        isUrl: true,
        displayName: t.bookTime,
        urlVariableName: 'bookTimeUrl',
        isVisibleVariableName: 'isBookTimeUrlVisible',
        faIcon: ['fal', 'calendar-plus'],
      },
      {
        isUrl: true,
        displayName: t.pay,
        urlVariableName: 'payUrl',
        isVisibleVariableName: 'isPayUrlVisible',
        faIcon: ['fal', 'money-bill-wave'],
      },
      {
        isUrl: true,
        displayName: t.shop,
        urlVariableName: 'shopUrl',
        isVisibleVariableName: 'isShopUrlVisible',
        faIcon: ['fal', 'store'],
      },
      {
        isUrl: true,
        displayName: t.portfolio,
        urlVariableName: 'portfolioUrl',
        isVisibleVariableName: 'isPortfolioUrlVisible',
        faIcon: ['fal', 'folder-open'],
      },
      {
        isUrl: true,
        displayName: t.resume,
        urlVariableName: 'resumeUrl',
        isVisibleVariableName: 'isResumeUrlVisible',
        faIcon: ['fal', 'file-user'],
      },
    ];
    // Handles
    const socialMediaHandles: SocialObject[] = [
      {
        isUrl: false,
        displayName: 'Bluesky',
        urlVariableName: 'blueskyHandle',
        isVisibleVariableName: 'isBlueskyHandleVisible',
        faIcon: ['fab', 'bluesky'],
        domain: 'https://bsky.app/profile/',
      },
      {
        isUrl: false,
        displayName: 'X',
        urlVariableName: 'twitterHandle',
        isVisibleVariableName: 'isTwitterHandleVisible',
        faIcon: ['fab', 'x-twitter'],
        domain: 'https://twitter.com/',
      },
      {
        isUrl: false,
        displayName: 'Instagram',
        urlVariableName: 'instagramHandle',
        isVisibleVariableName: 'isInstagramHandleVisible',
        faIcon: ['fab', 'instagram'],
        domain: 'https://www.instagram.com/',
      },
      {
        isUrl: false,
        displayName: 'Threads',
        urlVariableName: 'threadsHandle',
        isVisibleVariableName: 'isThreadsHandleVisible',
        faIcon: ['fab', 'threads'],
        domain: 'https://www.threads.net/',
      },
    ];
    const chatAppHandles: SocialObject[] = [
      {
        isUrl: false,
        displayName: 'Snapchat',
        urlVariableName: 'snapchatHandle',
        isVisibleVariableName: 'isSnapchatHandleVisible',
        faIcon: ['fab', 'snapchat'],
        domain: 'https://www.snapchat.com/add/',
      },
    ];

    const gamingHandles: SocialObject[] = [
      {
        isUrl: false,
        displayName: 'Twitch',
        urlVariableName: 'twitchHandle',
        isVisibleVariableName: 'isTwitchHandleVisible',
        faIcon: ['fab', 'twitch'],
        domain: 'https://www.twitch.tv/',
      },
    ];
    const commerceHandles: SocialObject[] = [
      {
        isUrl: false,
        displayName: 'OnlyFans',
        urlVariableName: 'onlyFansHandle',
        isVisibleVariableName: 'isOnlyFansHandleVisible',
        faIcon: ['fas', 'lock-alt'],
        domain: 'https://onlyfans.com/',
      },
    ];
    const miscHandles: SocialObject[] = [
      {
        isUrl: false,
        displayName: 'Medium',
        urlVariableName: 'mediumHandle',
        isVisibleVariableName: 'isMediumHandleVisible',
        faIcon: ['fab', 'medium'],
        domain: 'https://medium.com/',
      },
    ];

    const socialMediaOptions: SocialObject[] = [...socialMediaLinks, ...socialMediaHandles];
    const chatAppOptions: SocialObject[] = [...chatAppLinks, ...chatAppHandles];
    const videoOptions: SocialObject[] = [...videoLinks];
    const audioOptions: SocialObject[] = [...audioLinks];
    const gamingOptions: SocialObject[] = [...gamingLinks, ...gamingHandles];
    const commerceOptions: SocialObject[] = [...commerceLinks, ...commerceHandles];
    const paymentOptions: SocialObject[] = [...paymentLinks];
    const developerOptions: SocialObject[] = [...developerLinks];
    const miscOptions: SocialObject[] = [...miscLinks, ...miscHandles];
    const genericOptions: SocialObject[] = [...genericLinks];

    const allOptions = [socialMediaOptions, chatAppOptions, videoOptions, audioOptions, gamingOptions, commerceOptions, paymentOptions, developerOptions, miscOptions, genericOptions];

    const variablesToAddToData: Record<string, unknown> = {};

    allOptions.forEach((optionArray) => {
      optionArray.forEach((social) => {
        variablesToAddToData[social.urlVariableName] = null;
        variablesToAddToData[social.isVisibleVariableName] = false;
      });
      optionArray.sort((a: SocialObject, b: SocialObject) => {
        const textA = a.displayName.toUpperCase();
        const textB = b.displayName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    });

    return {
      modeForm: {
        ...getEmptyProfileMode(),
        ...variablesToAddToData,
      },
      imageSizes: { small: 400 },
      socialMediaOptions,
      chatAppOptions,
      videoOptions,
      audioOptions,
      gamingOptions,
      commerceOptions,
      paymentOptions,
      developerOptions,
      miscOptions,
      genericOptions,
      allOptions: allOptions.flat(),
      showCtaButtonOptions: false,
      showAdvancedOptions: false,
    };
  },
  computed: {
    contactDetailsUrlOptionSelected(): boolean {
      return this.modeForm.contactDetailsCustomFormModeId === contactDetailsFormUrlOptionConstant;
    },
    modesArray(): AnyMode[] {
      const modes = Object.values(this.$store.state.modes) as AnyMode[];
      return modes.filter((mode: AnyMode) => mode.docId !== this.$data.genericModeDataForm.docId);
    },
    customFormModesArray(): { docId: string; name: string }[] {
      const modes = Object.values(this.$store.state.modes) as AnyMode[];
      return [
        { docId: '', name: t.autoGenerateContactDetailsForm },
        ...modes.filter((mode: AnyMode) => mode.type === eModeType.customForm),
        { docId: contactDetailsFormUrlOptionConstant, name: t.redirectToUrl },
      ];
    },
  },
  created() {
    if (!this.$store.state.brandIconsAlreadyAdded) {
      library.add(
        faLinkedin,
        faFacebook,
        faYoutube,
        faSnapchat,
        faXTwitter,
        faBluesky,
        faInstagram,
        faThreads,
        faMedium,
        faTwitch,
        faSpotify,
        faPatreon,
        faReddit,
        faSoundcloud,
        faGithub,
        faDiscord,
        faGitlab,
        faStripeS,
        faTiktok,
        faDeviantart,
        faAppStoreIos,
        faGooglePlay,
        faKickstarterK,
        faAmazon,
        faPinterest,
        faVimeo,
        faWordpress,
        faShopify,
        faEtsy,
        fa500px,
        faEbay,
        faSteam,
        faPlaystation,
        faXbox,
        faItchIo,
        faSlack,
        faWhatsapp,
        faNpm,
        faMicrosoft,
        faMeetup,
        faAirbnb,
        faBitbucket,
        faFacebookMessenger,
        faMixcloud,
        faQuora,
        faStackOverflow,
        faWeixin,
        faBandcamp,
        faBitcoin,
        faPaypal,
        faProductHunt,
        faApplePay,
        faGooglePay,
        faTelegram,
        faYelp,
        faTumblr,
        faBattleNet,
        faFolderOpen,
        faFileUser,
      );
      this.$store.commit('brandIconsAlreadyAdded', true);
    }
  },
  watch: {
    modeForm() {
      this.onLoad();
    },
    showCtaButtonOptions(ctaWasToggledOff) {
      if (!ctaWasToggledOff) {
        this.modeForm.ctaButtonLabel = '';
        this.modeForm.ctaButtonUrl = '';
      }
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.checkForDeletedCustomForms();
    },
    onAllowPeopleToSendTheirContactDetailsChange() {
      if (!this.modeForm.allowPeopleToSendTheirContactDetails) {
        this.modeForm.showContactDetailsPrompt = false;
      }
    },
    checkForDeletedCustomForms() {
      const selectedCustomForm = this.customFormModesArray.find((mode) => mode.docId === this.modeForm.contactDetailsCustomFormModeId);

      // If the custom form we were using is deleted, reset this.
      if (!selectedCustomForm) {
        this.modeForm.contactDetailsCustomFormModeId = '';
        this.modeForm.allowPeopleToSendTheirContactDetails = false;
      }
    },
    onGetAddressData(addressData: any, place: any) {
      this.modeForm.locationAddress = getLocationAddress(addressData, place, this.modeForm);
    },
    onAddressInputChange(input: string) {
      if (!input) {
        this.modeForm.locationAddress = getEmptyDetailedAddress();
      }
    },
    updateRedirectUrlWithSitchUrl(docId: string) {
      this.modeForm.ctaButtonUrl = `${sitchClientUrlNotLocalhost}?u=${this.$store.state.userId}&am=${docId}`;
    },
    onFormPopulatedForEdit() {
      this.showCtaButtonOptions = Boolean(this.modeForm.ctaButtonUrl);
    },
    onSelectSocial(social: SocialObject) {
      (this.modeForm as any)[social.isVisibleVariableName] = !(this.modeForm as any)[social.isVisibleVariableName];
      this.$forceUpdate();
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const promiseArray: Promise<any>[] = [];
      const combinedModeData = getCombinedModeFormData(this) as ProfileMode;
      const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;

      // If everything was deleted just remove the description altogether.
      if (combinedModeData.description.replace(/<\/?[^>]+(>|$)/g, '') === '') {
        combinedModeData.description = '';
      }
      // Create the contact details form if needed
      if (combinedModeData.allowPeopleToSendTheirContactDetails && !combinedModeData.contactDetailsCustomFormModeId) {
        if (isAtMaxModes()) {
          showPrompt(t.cannotMakeContactDetailsForm);
          this.modeForm.allowPeopleToSendTheirContactDetails = false;
          this.modeForm.contactDetailsCustomFormModeId = '';
          return;
        }
        const docId = generateId();
        combinedModeData.contactDetailsCustomFormModeId = docId;
        const customFormMode: CustomFormMode = {
          ...getEmptyCustomFormMode(),
          docId,
          linkId: '',
          themeId: '',
          index: 0,
          type: eModeType.customForm,
          name: t.contactDetailsFormTitle.supplant([combinedModeData.name]),
          displayName: t.shareYourInfo,
          message: '',
          requireName: true,
          requireEmail: true,
          requirePhoneNumber: false,
          customFields: [
            {
              id: generateId(),
              name: t.phone,
              type: eCustomFieldTypes.phoneNumber,
              description: '',
              choices: [],
              isRequired: false,
              isHidden: false,
            },
            {
              id: generateId(),
              name: t.message,
              type: eCustomFieldTypes.longText,
              description: '',
              choices: [],
              isRequired: false,
              isHidden: false,
            },
          ],
          emailForReceivingNotifications: this.$store.state.currUser.email || '',
        };
        saveMode(customFormMode).catch(() => {
          combinedModeData.allowPeopleToSendTheirContactDetails = false;
          combinedModeData.contactDetailsCustomFormModeId = '';
        });
      }

      handleImages(
        combinedModeData,
        [
          {
            key: 'images',
            imageSizes: this.imageSizes,
          },
        ],
        promiseArray,
        storagePath
      );

      Promise.all(promiseArray).then(() => {
        saveMode({ ...combinedModeData });
      });
    },
  },
});
